import React from 'react'

export default function Contact() {
    return (
        <section className="contact" id="contact">
            <div className="container">
                <h1>Kontakt</h1>
                <div className="contact--source">
                    <div className="contact--source-plain">
                        <p>email: <a href="mailto:angelikagurbisz@poczta.onet.pl"><span>angelikagurbisz@poczta.onet.pl</span></a></p>
                        <p>numer telefonu: <span>ukryty</span></p>                
                    </div>
                    <div className="contact--source-media">
                        <p><a href="https://www.instagram.com/zapisane_w_fotografii/"><i className="fab fa-instagram"></i></a></p>
                    </div>
                </div>
            </div> 
        </section>

    )
}
