import React from 'react';
import AboutBox from './AboutBox';

const aboutContent = [
    {
        text: "Cześć, jestem Angelika!",
        url: "./assets/image1.jpg"
    },
    {
        text: "Zapisuję w fotografii emocje",
        url: "./assets/image2.jpg"
    },
    {
        text: "Uwieczniam cudowne chwilę",
        url: "./assets/image3.jpg"
    }
]

export default function About() {
    return (
        <section className="about" id="about">
            <div className="container">
                <div className="about--wrapper">
                    <AboutBox aboutData={aboutContent} />
                </div>

            </div>

        </section>
    )
}
