import React from 'react'
import Item from './Item'

const links = [
    {
        name : "Strona główna",
        a : "#"
    },
    {
        name : "O mnie",
        a : "#about"
    },
    {
        name : "Galeria",
        a : "#"
    },
    {
        name : "Kontakt",
        a : "#contact"
    }]

const item = links.map(({name, a}, index) =>  <Item key={index} name={name} a={a}></Item>) 

const List = () =>
    (
        <ul className="navigation--list">{item}</ul>
    )


export default List;
