import React from 'react';
import List from './List';
import styled from 'styled-components';

const Nav = styled.nav`
& ::after {
  height: 10px;
  width: 94%;
  position: absolute;
  bottom: 0;
  left: 3%;
  background-color: #947c8f;
  opacity: ${props => ((props.isHide) ? 0 : 1 )};
  transition-duration: 2s;
}`


class Navigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {isHide: false}
    this.handleOnScroll = this.handleOnScroll.bind(this)
    this.height = window.screen.availHeight
  }


  handleOnScroll(){
    if (window.scrollY > this.height) {
    return this.setState({isHide: true})
    } else {
    return this.setState({isHide: false})
    }
  }

  forScrolling(){
    window.addEventListener('scroll', this.handleOnScroll);
    console.log(this.state.isHide)
  }

  render() {
    this.forScrolling()
    return (
    <div className="background">
      <div className="container">
        <Nav className="navigation" isHide={this.state.isHide}>
          <div className="logo"></div>
          <List />
        </Nav>
      </div>
    </div>
    )
  }
  
}

export default Navigation;
