import React from 'react'
import Navigation from './Navigation';
import Hero from './Hero';
import About from './About';
import Contact from './Contact';
import Separation from './Separation';



export default function App() {
    return (
        <>
            <Navigation />
            <Hero />
            <About />
            <Separation />
            <Contact />
        </>
    )
}
