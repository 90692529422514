/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'

export default function AboutBox({aboutData}) {
    return (
        aboutData.map(({text, url}, index)=> 
        <div className="about--box" key={index}>
            <div className="about--box-dsc"><h1 className="about--box-dsc-txt">{text}</h1></div>
            <div className="about--box-img"><img src={url} alt="children picture"/></div>
        </div>
        )
    )
}

