import React from 'react'
import BackgroundSlider from 'react-background-slider'
import image1 from './assets/image1.jpg'
import image2 from './assets/image3.jpg'
import image3 from './assets/image5.jpg'
import image4 from './assets/image6.jpg'

export default function Hero() {
    return (
        <section className="hero">
            
            <div className="hero--background">
                <div className="hero--shadow">
                    <div className="container">
                        <div className="hero--content">
                            {/* <span className="hero--content-left first">Fotografia noworodkowa,</span>
                            <span className="hero--content-right second">dziecięca i nie tylko!</span> */}
                        </div>
                    </div>
                </div>
            </div>
            <BackgroundSlider
            images={[image1, image2, image3, image4]}
            duration={3} transition={1} />
        </section>
    )
}
